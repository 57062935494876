var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("portal-target", {
    attrs: {
      name: "octopus-payments-widget-portal",
      "data-test": "octopus-payments-widget-portal",
      "slot-props": {
        orderId: _vm.orderId,
        externalId: _vm.externalId,
        mode: _vm.mode
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
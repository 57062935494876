var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      ref: "wrapperRef",
      staticClass: "document-management",
      attrs: { "data-test": "document-management-section" }
    },
    [
      _c("header", { staticClass: "document-management__header" }, [
        _c("h2", { staticClass: "subsection-title" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("finance/document-management-section-title")) +
              "\n    "
          )
        ])
      ]),
      _vm._v(" "),
      _vm.billingInfo && _vm.missingBillingInfo.length > 0
        ? _c(
            "AlertMessage",
            {
              staticClass: "document-management__warning",
              attrs: { type: "warning", "data-test": "billing-info-warning" }
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.$tc(
                      "finance/document-management-section-missing-info",
                      _vm.missingBillingInfo.length,
                      { missingBillingInfo: _vm.missingBillingInfoMessage }
                    )
                  ) +
                  "\n    " +
                  _vm._s(
                    _vm.$t(
                      "finance/document-management-section-update-payment-info_one"
                    )
                  ) +
                  "\n    "
              ),
              _c("SalesforceLink", { attrs: { companyId: _vm.carrierId } }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t(
                        "finance/document-management-section-update-payment-info_two"
                      )
                    ) +
                    "\n    "
                )
              ]),
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.$t(
                      "finance/document-management-section-update-payment-info_three"
                    )
                  ) +
                  "\n  "
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading
        ? [
            _c("PaymentMessagePortal", {
              staticClass: "carrier-order-payment__message"
            }),
            _vm._v(" "),
            _c("PODReviewWidget", {
              attrs: {
                orderId: _vm.orderId,
                editable: _vm.editable,
                externalId: _vm.externalId
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
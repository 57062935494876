var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "div",
        { staticClass: "closing-order" },
        [
          _c(
            "div",
            { staticClass: "closing-order__charges" },
            [
              !_vm.showSettlementCustomerPrice &&
              _vm.canShowBreakdowns &&
              !_vm.hideCustomerCharges
                ? _c("CustomerChargesBreakdown", {
                    staticClass: "closing-order__breakdown",
                    attrs: {
                      orderId: _vm.order.id,
                      paymentInfo: _vm.paymentInfo,
                      canAddSurcharge: _vm.canAddCustomerSurcharge,
                      canCloseBreakdown: _vm.canCloseCustomerPrice,
                      basePrice: _vm.order.basePrice,
                      totalNetPrice: _vm.order.totalNetPrice,
                      isCancelled: _vm.isCancelled,
                      compact: "",
                      "data-test": "customer-breakdown"
                    },
                    on: { "breakdown-closed": _vm.handleClosedBreakdown }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isEnableClaimAccessorial
            ? [
                _vm.canUserAccessClaims
                  ? _c("Claims", {
                      staticClass: "closing-order__claims",
                      attrs: {
                        orderId: _vm.orderId,
                        editable: "",
                        "data-test": "closing-order__claims__finance-test"
                      }
                    })
                  : _vm._e()
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.paymentInfo
            ? _c("CarrierOrderPayment", {
                staticClass: "closing-order__payment-information",
                attrs: {
                  orderId: _vm.orderId,
                  paymentInfo: _vm.paymentInfo,
                  carrierId: _vm.order.carrierCompanyId,
                  externalId: _vm.externalId
                }
              })
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
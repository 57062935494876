var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "finance-order-main-carrier" },
    [
      _vm.costCentreEnabled
        ? _c("CostCentreOrder", {
            attrs: {
              orderId: _vm.order.id,
              orderIdForStaff: _vm.order.idForStaff,
              carrierId: _vm.order.carrierCompanyId,
              partnerManagerId: _vm.order.partnerManagerId
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("OctopusPaymentsWidgetPortal", {
        staticClass: "finance-order-main-carrier__breakdown",
        attrs: {
          orderId: _vm.order.id,
          externalId: _vm.order.idForStaff,
          "data-test": "octopus-payments-widget-portal",
          mode: "ap"
        }
      }),
      _vm._v(" "),
      _vm.showLegacyCarrierInvoices
        ? _c("CarrierInvoices", {
            attrs: { orderId: _vm.order.id, compact: _vm.compact }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isEnableClaimAccessorial
        ? [
            _c("Claims", {
              staticClass: "finance-order-main-carrier__claims",
              attrs: {
                orderId: _vm.order.id,
                "data-test": "finance-order-main-carrier__finance-test"
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("DocumentManagementSection", {
        attrs: {
          orderId: _vm.order.id,
          carrierId: _vm.order.carrierCompanyId,
          externalId: _vm.order.idForStaff
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
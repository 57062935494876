var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "finance-order-footer" },
    [
      _c("OrderStageFooter", {
        staticClass: "finance-order-footer__content",
        attrs: {
          accountManager: _vm.accountManager,
          juniorAccountManager: _vm.juniorAccountManager,
          partnerManager: _vm.partnerManager,
          spotBidder: _vm.spotBidder,
          accountManagerDisabled: "",
          partnerManagerDisabled: "",
          orderRegularity: _vm.orderRegularity,
          orderState: _vm.orderState
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex flex-shrink-0" },
                  [
                    !_vm.showPaymentsWidgetStartPayment &&
                    !_vm.isEligibleForAutomaticCreditNotes
                      ? _c(
                          "RequirementsPopover",
                          {
                            staticClass: "finance-order-footer__button",
                            attrs: {
                              open: _vm.isPopoverVisible,
                              requirements: _vm.paymentRequirements,
                              autoHide: false,
                              header: _vm.$t(
                                "finance/finance-order-footer-payment-requirements-header"
                              ),
                              placement: "top",
                              trigger: "manual",
                              "data-test": "payment-requirements"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                attrs: {
                                  "data-test": "start-payment-button-wrapper"
                                },
                                on: {
                                  mouseover: _vm.hoverStartPaymentBtn,
                                  mouseout: _vm.unhoverStartPaymentBtn
                                }
                              },
                              [
                                _c("Button", {
                                  staticClass: "start-payment-button",
                                  attrs: {
                                    disabled: _vm.isStartPaymentBtnDisabled,
                                    category: "primary",
                                    text: _vm.$t(
                                      "finance/finance-order-footer-start-payment-label"
                                    ),
                                    "data-test": "start-payment-button"
                                  },
                                  on: { click: _vm.openStartPaymentModal }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showPaymentsWidgetStartPayment
                      ? _c("StartPaymentPortal")
                      : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c(
        "StartPaymentModal",
        {
          ref: "startPaymentModal",
          staticClass: "finance-order-footer__modal payment-modal",
          attrs: {
            orderId: _vm.orderId,
            title: _vm.$t(
              "finance/finance-order-footer-start-payment-modal-title"
            ),
            confirmButtonText: _vm.$t(
              "finance/finance-order-footer-start-payment-modal-confirm-label"
            ),
            loading: _vm.paymentInProgress
          },
          on: { confirmed: _vm.startPaymentProcess }
        },
        [
          _c("p", { staticClass: "subtitle payment-modal__text" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t(
                    "finance/finance-order-footer-start-payment-modal-description"
                  )
                ) +
                "\n    "
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("portal-target", {
        attrs: {
          name: "pod-review-widget",
          "slot-props": {
            orderId: _vm.orderId,
            editable: _vm.editable,
            includeTracking: _vm.includeTracking,
            disabled: _vm.disabled,
            externalId: _vm.externalId
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }


































































import {
  onBeforeMount,
  ref,
  toRef,
  computed,
  defineComponent,
  PropType,
} from '@vue/composition-api'
import { appOptions } from '/@/app-options'
import StartPaymentModal from './StartPaymentModal.vue'
import usePaymentActions from '../compositions/usePaymentActions'
import useOrderPaymentStatus from '../compositions/useOrderPaymentStatus'
import { ExtendedPerson, OrderState, OrderRegularity } from '/@/app-options'
import { Button, RequirementsPopover } from '@sennder/plankton'
import StartPaymentPortal from './microfrontends/StartPaymentPortal.vue'

export default defineComponent({
  components: {
    OrderStageFooter: () => Promise.resolve(appOptions.components.OrderStageFooter),
    StartPaymentModal,
    Button,
    RequirementsPopover,
    StartPaymentPortal
},
  props: {
    orderId: {
      type: Number,
      required: true,
    },
    orderState: {
      type: String as PropType<OrderState>,
      default: 'NEW',
    },
    accountManager: {
      type: Object as PropType<ExtendedPerson>,
      default: null,
    },
    juniorAccountManager: {
      type: Object as PropType<ExtendedPerson>,
      default: null,
    },
    partnerManager: {
      type: Object as PropType<ExtendedPerson>,
      default: null,
    },
    spotBidder: {
      type: Object as PropType<ExtendedPerson>,
      default: null,
    },
    orderRegularity: {
      type: Object as PropType<OrderRegularity>,
      default: null,
    },
  },
  setup(props, context) {
    const startPaymentModal = ref(null)
    const orderId = toRef(props, 'orderId')
    const paymentInProgress = ref(false)

    const showPaymentsWidgetStartPayment = computed<boolean>(() => {
      return appOptions.useFeatureFlag().isActive('START_PAYMENT_MEXIT').value
    })

    const {
      isStartPaymentBtnDisabled,
      paymentRequirements,
      isPopoverVisible,
      hoverStartPaymentBtn,
      unhoverStartPaymentBtn,
      startPayment
    } = usePaymentActions({
      orderId,
      $t: context.root.$t.bind(context.root),
    })

    const { paymentInfo, fetchPaymentInformation } =
      useOrderPaymentStatus(orderId)
    onBeforeMount(fetchPaymentInformation)
    const isEligibleForAutomaticCreditNotes = computed(
      () => paymentInfo.value?.isEligibleForAutomaticCreditNotes
    )

    const startPaymentProcess = async () => {
      try {
        paymentInProgress.value = true
        await startPayment()
        await fetchPaymentInformation()
        closeStartPaymentModal()
      } finally {
        paymentInProgress.value = false
      }
    }

    const openStartPaymentModal = () => {
      return startPaymentModal.value.open()
    }
    const closeStartPaymentModal = () => {
      return startPaymentModal.value.close()
    }

    return {
      startPaymentModal,
      isStartPaymentBtnDisabled,
      paymentRequirements,
      isPopoverVisible,
      hoverStartPaymentBtn,
      unhoverStartPaymentBtn,
      openStartPaymentModal,
      isEligibleForAutomaticCreditNotes,
      startPaymentProcess,
      closeStartPaymentModal,
      paymentInProgress,
      showPaymentsWidgetStartPayment,
    }
  },
})

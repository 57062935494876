




























































































































import get from 'lodash/get'
import { formatCurrency } from '@sennder/design-system-core'
import { CancelOrderButton } from '@sennder/incident-management'
import LabeledText from '/@/components/shared/LabeledText.vue'
import { formatMoneyWithDashForNull } from '/@/filters/filters'
import SalesforceLink from '/@/components/SalesforceLink.vue'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { appOptions, FinanceOrder } from '/@/app-options'
import useOrderCancellation from '/@/compositions/useOrderCancellation'
import LabeledMargin from './LabeledMargin.vue'
import { FinanceView } from '/@/components/types'
import {
  publish,
  PaymentStatusUpdateSideEffect,
  MicrofrontendEvents,
} from '@sennder/senn-node-microfrontend-event-bus'
import useCurrency from '/@/compositions/useCurrency'
import { Currency } from '/@/constants'

export default defineComponent({
  components: {
    CancelOrderButton,
    LabeledText,
    SalesforceLink,
    LabeledMargin,
    OrderStateChip: () => Promise.resolve(appOptions.components.OrderStateChip),
  },
  props: {
    order: { type: Object as PropType<FinanceOrder>, required: true },
    view: { type: String as PropType<FinanceView>, required: true },
  },
  setup(props, context) {
    const {
      refreshOrderLogs,
      refreshOrderIncidents,
      toggleSidebar,
      expandSidebar,
    } = appOptions.libraries.useOrderActivitySidebar()
    const { currentUserIsOps } = appOptions.useCurrentUser()
    const { canCancelCurrentOrder, cancelCurrentOrder } = useOrderCancellation()
    const { exchangeRates } = useCurrency()

    const goToOrderDetailView = () => {
      const idForStaff = props.order.idForStaff
      context.root.$router.push(`/ordering/${idForStaff}`)
    }

    const changeFinanceView = () => {
      const idForStaff = props.order.idForStaff
      const newFinanceView: FinanceView = props.view === 'AR' ? 'AP' : 'AR'
      context.root.$router.push(
        `/finance/ordering/${idForStaff}/${newFinanceView.toLowerCase()}`
      )
    }

    const changeFinanceViewLabel = computed(() => {
      if (props.view === 'AP')
        return context.root.$t('finance/finance-order-customer-view-label')
      if (props.view === 'AR')
        return context.root.$t('finance/finance-order-carrier-view-label')
      return ''
    })

    const handleOrderCancellation = async () => {
      await cancelCurrentOrder()
      await refreshOrderLogs()

      publish<PaymentStatusUpdateSideEffect>({
        name: MicrofrontendEvents.PaymentStatusUpdateSideEffect,
        data: {},
      })
    }

    const reportCancellationIncident = async () => {
      await Promise.all([refreshOrderIncidents(), handleOrderCancellation()])
      context.emit('incident-reported')
      expandSidebar()
    }

    const isCostConverted = computed<boolean>(() => {
      return (
        !!props.order.carrierPaymentCurrency &&
        props.order.carrierPaymentCurrency !== Currency.EUR
      )
    })

    const costInCarrierCurrency = computed<string | null>(() => {
      /**
       * Do nothing if order is not converted or doesn't have a cost yet.
       */
      if (
        !isCostConverted.value ||
        !props.order.totalNetCost ||
        !props.order.carrierPaymentCurrency
      )
        return null

      /**
       * This is not expected.
       */
      if (
        !exchangeRates[Currency.EUR] ||
        !exchangeRates[Currency.EUR].rates[props.order.carrierPaymentCurrency]
      ) {
        // eslint-disable-next-line no-console
        console.error('Exchange rate was not found when it was required')
        return null
      }

      return formatCurrency(
        props.order.totalNetCost *
          exchangeRates[Currency.EUR].rates[props.order.carrierPaymentCurrency],
        props.order.carrierPaymentCurrency
      )
    })

    return {
      get,
      currentUserIsOps,
      goToOrderDetailView,
      changeFinanceView,
      toggleSidebar,
      canCancelCurrentOrder,
      changeFinanceViewLabel,
      formatMoneyWithDashForNull,
      reportCancellationIncident,
      isCostConverted,
      costInCarrierCurrency,
    }
  },
})















































import CustomerChargesBreakdown from '/@/components/breakdowns/CustomerChargesBreakdown.vue'
import CarrierOrderPayment from '/@/components/carrier-order-payment/CarrierOrderPayment.vue'
import Claims from '/@/components/claims/Claims.vue'
import { defineComponent, toRef, computed, watch } from '@vue/composition-api'
import useOrderPaymentStatus from '/@/compositions/useOrderPaymentStatus'
import fetchDataOnMounted from '/@/compositions/useFetchDataOnMounted'
import * as USER_PERMISSIONS from '../user-permissions'
import { appOptions } from '../app-options'
import {
  publish,
  PaymentStatusUpdateSideEffect,
  MicrofrontendEvents,
} from '@sennder/senn-node-microfrontend-event-bus'

export default defineComponent({
  components: {
    CustomerChargesBreakdown,
    CarrierOrderPayment,
    Claims,
  },
  props: {
    orderId: { type: Number, required: true },
    /**
     * Need it for watching for changes in the order
     */
    isCancelled: { type: Boolean, default: false },
    hideCustomerCharges: { type: Boolean, default: false },
  },
  setup(props, context) {
    const orderId = toRef(props, 'orderId')
    const { order, loadOrderDetail } = appOptions.useOrder()
    const externalId = order.value.idForStaff
    const { paymentInfo, fetchPaymentInformation } =
      useOrderPaymentStatus(orderId)
    const {
      currentUserIsAccountManager,
      currentUserIsPartnerManager,
      currentUserIsCallCenter,
      currentUserIsClaimAccessorialManager,
      hasPermission,
    } = appOptions.useCurrentUser()

    const loading = fetchDataOnMounted([
      () => loadOrderDetail(props.orderId),
      fetchPaymentInformation,
    ])

    const canAddCustomerSurcharge = computed(
      () =>
        hasPermission(USER_PERMISSIONS.CAN_ADD_CUSTOMER_SURCHARGES) &&
        !paymentInfo.value.isCustomerPriceClosed
    )
    const canCloseCustomerPrice = computed(
      () =>
        order.value.state === 'EXECUTED' &&
        currentUserIsAccountManager.value &&
        !paymentInfo.value.isCustomerPriceClosed
    )

    const showSettlementCustomerPrice = computed(
      () =>
        appOptions.useFeatureFlag().isActive('ENABLE_SHIPPER_PRICE_WIDGET')
          .value
    )

    const isEnableClaimAccessorial = computed(() => {
      return appOptions.useFeatureFlag().isActive('ENABLE_CLAIM_ACCESSORIAL').value
    })

    const canUserAccessClaims = computed(
      () =>
        currentUserIsPartnerManager.value ||
        currentUserIsAccountManager.value ||
        currentUserIsClaimAccessorialManager.value
    )
    const canShowBreakdowns = computed(() => !currentUserIsCallCenter.value)
    const handleClosedBreakdown = () => context.emit('breakdown-closed')

    const updatePaymentInformation = () => {
      fetchPaymentInformation()

      publish<PaymentStatusUpdateSideEffect>({
        name: MicrofrontendEvents.PaymentStatusUpdateSideEffect,
        data: {},
      })
    }

    watch(
      () => props.isCancelled,
      () => loadOrderDetail(props.orderId)
    )

    return {
      order,
      externalId,
      paymentInfo,
      loading,
      canAddCustomerSurcharge,
      canCloseCustomerPrice,
      canShowBreakdowns,
      handleClosedBreakdown,
      canUserAccessClaims,
      updatePaymentInformation,
      showSettlementCustomerPrice,
      isEnableClaimAccessorial
    }
  },
})











import Vue from 'vue'

export default Vue.extend({
  name: 'PODReviewWidget',
  props: {
    disabled: Boolean,
    includeTracking: Boolean,
    orderId: {
      type: Number,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    externalId: {
      type: String,
      required: true
    }
  },

})
